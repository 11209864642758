import React from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreescoutDeviceList } from "../../../../API/treescout";
import styles from "./Treescout.module.css";
import { Outlet, useNavigate, useParams } from "react-router";

interface TreescoutProps {
  treescoutDevices: TreescoutDeviceList;
}

export default function Treescout({
  treescoutDevices,
}: TreescoutProps): React.ReactNode {
  const { t } = useTranslation();
  const { tmsId } = useParams();
  const navigate = useNavigate();

  return (
    <div className={styles.treescoutPage}>
      <div className={styles.container}>
        {treescoutDevices.map((device) => (
          <Accordion
            key={device.id}
            className={styles.accordion}
            expanded={tmsId === device.id}
            onChange={() => navigate(tmsId === device.id ? "" : device.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={styles.summary}
              aria-label="label.accordion summary"
              sx={{
                backgroundColor: "#f5f5f5",
                "&:hover": {
                  backgroundColor: "#eeeeee",
                },
              }}
            >
              <div className={styles.headerContent}>
                <div>
                  <Typography variant="subtitle1">
                    {t("image.Serial number")}
                  </Typography>
                  {device.serial_number}
                </div>
                <div>
                  <Typography variant="subtitle1">
                    {t("image.Status")}
                  </Typography>
                  {t(`device.status.${device.sending_messages}`)}
                </div>
              </div>
            </AccordionSummary>

            <AccordionDetails>
              {tmsId == device.id && <Outlet />}
            </AccordionDetails>
          </Accordion>
        ))}

        {treescoutDevices.length === 0 && (
          <p className={styles.noDevice}>{t("label.No devices found")}</p>
        )}
      </div>
    </div>
  );
}
