import React from "react";
import { Navigate, RouteObject, LoaderFunction } from "react-router-dom";
import { getCustomerFieldsAndResultSets } from "../../../API/fields";
import Dashboard from "../../../Component/Dashboard/Dashboard";
import field from "./field";
import order from "./order";
import taskmap from "./taskmap";
import support from "./support";
import treescout from "./treescout";

const routes: RouteObject = {
  path: ":customerId",
  Component: Dashboard,
  loader: getCustomerFieldsAndResultSets as LoaderFunction,
  id: "customer",
  children: [
    {
      index: true,
      element: React.createElement(Navigate, { to: "field" }),
    },
    taskmap,
    field,
    order,
    treescout,
    support,
  ],
};

export default routes;
