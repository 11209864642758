import React from "react";
import { useLoaderData } from "react-router-dom";
import { TreescoutDeviceList } from "../../../../API/treescout";
import Treescout from "./Treescout";

export default function TreescoutPage(): React.ReactNode {
  const treescoutDevices = useLoaderData() as TreescoutDeviceList;

  return <Treescout treescoutDevices={treescoutDevices} />;
}
