import React from "react";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@mui/icons-material";
import Button from "../Button/Button";
import classNames from "classnames";

type ArrowLocation = "up" | "down" | "left" | "right";

type CollapsibleProps = {
  children: React.ReactNode;
  position?: ArrowLocation;
  label: {
    open: string;
    close: string;
  };
  className?: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  defaultOpen?: boolean;
};

type ToggleButtonProps = {
  className: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
  label: {
    open: string;
    close: string;
  };
  position: ArrowLocation;
  buttonLabel?: string;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  className,
  isOpen,
  onOpenChange,
  handleKeyDown,
  label,
}) =>
  isOpen ? (
    <button
      className={className}
      onClick={() => onOpenChange(!isOpen)}
      onKeyDown={handleKeyDown}
      aria-expanded={isOpen}
      aria-label={label.close}
    >
      {<KeyboardArrowDown />}
    </button>
  ) : (
    <Button
      variant="contained"
      className={className}
      onClick={() => onOpenChange(!isOpen)}
      onKeyDown={handleKeyDown}
      aria-expanded={isOpen}
      aria-label={label.close}
      startIcon={<KeyboardArrowUp />}
    >
      {label.open}
    </Button>
  );

const CollapsibleToggleButton: React.FC<ToggleButtonProps> = ({
  className,
  isOpen,
  onOpenChange,
  handleKeyDown,
  label,
}) => (
  <button
    className={className}
    onClick={() => onOpenChange(!isOpen)}
    onKeyDown={handleKeyDown}
    aria-expanded={isOpen}
    aria-label={isOpen ? label.close : label.open}
  >
    {isOpen ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
  </button>
);

export function Collapsible({
  children,
  position = "left",
  label,
  className = "",
  isOpen,
  onOpenChange,
}: CollapsibleProps): React.ReactNode {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onOpenChange(!isOpen);
    }
  };

  return (
    <div className="relative flex">
      {position === "left" && (
        <CollapsibleToggleButton
          position={position}
          className={className}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          handleKeyDown={handleKeyDown}
          label={label}
        />
      )}
      {isOpen && children}
      {position === "right" && (
        <CollapsibleToggleButton
          position={position}
          className={className}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          handleKeyDown={handleKeyDown}
          label={label}
        />
      )}
    </div>
  );
}

export function DownwardCollapsible({
  children,
  position = "left",
  label,
  className = "",
  isOpen,
  onOpenChange,
}: CollapsibleProps): React.ReactNode {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault();
      onOpenChange(!isOpen);
    }
  };

  return (
    <div className="relative flex flex-col">
      {position === "left" && (
        <ToggleButton
          position={position}
          className={classNames(className, {
            open: isOpen,
            collapsed: !isOpen,
          })}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          handleKeyDown={handleKeyDown}
          label={label}
          buttonLabel={label.close}
        />
      )}
      {isOpen && children}
      {position === "right" && (
        <ToggleButton
          position={position}
          className={classNames(className, {
            open: isOpen,
            collapsed: !isOpen,
          })}
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          handleKeyDown={handleKeyDown}
          label={label}
          buttonLabel={label.close}
        />
      )}
    </div>
  );
}
