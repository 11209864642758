import React, { ReactNode, useEffect } from "react";
import {
  Stack,
  CircularProgress,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import { ArrowBack, ArrowForward, PhotoCamera } from "@mui/icons-material";
import { SensorImage } from "../../../../../API/treescout";
import styles from "../Treescout.module.css";
import {
  Await,
  Outlet,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router";
import DisplayDate from "../../../../../Component/DisplayDate/DisplayDate";
import { DateTime } from "luxon";

function ImageLoading(): ReactNode {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={styles.loadingContainer}
    >
      <CircularProgress />
    </Stack>
  );
}

export function TreescoutImage(): ReactNode {
  const { t } = useTranslation();
  const { url, cameraPosition } = useLoaderData();

  return (
    <div className={styles.imageWrapper}>
      <React.Suspense fallback={<ImageLoading />}>
        <Await resolve={url}>
          {(actualUrl) => (
            <div>
              {cameraPosition === "left" && (
                <Tooltip title={t("image.Left Camera")} placement="top-end">
                  <PhotoCamera className={styles.leftText} />
                </Tooltip>
              )}

              <img src={actualUrl} alt="" className={styles.carouselImage} />

              {cameraPosition === "right" && (
                <Tooltip title={t("image.Right Camera")} placement="top-start">
                  <PhotoCamera className={styles.rightText} />
                </Tooltip>
              )}
            </div>
          )}
        </Await>
      </React.Suspense>
    </div>
  );
}

export default function TreescoutDetailsPage() {
  const { sensorImageId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const images = useLoaderData<SensorImage[]>();
  const currentIndex = images?.findIndex((image) => image.id === sensorImageId);

  useEffect(() => {
    if (images?.length && currentIndex === -1) {
      navigate(images[0].id);
    }
  }, [images, currentIndex]);

  const handlePagination = (
    _event: React.ChangeEvent<unknown>,
    page: number,
  ): void => {
    const newIndex = page - 1;

    if (newIndex >= 0 && newIndex < images?.length) {
      navigate(images[newIndex].id);
    }
  };

  return (
    <>
      {images.length > 0 ? (
        <div className={styles.imagesContainer}>
          <Outlet />
          <div className={styles.imageDate}>
            <DisplayDate
              date={images[currentIndex]?.timestamp}
              format={DateTime.DATETIME_MED}
            />
          </div>
          <div className={styles.controlsContainer}>
            <div className={styles.paginationWrapper}>
              <Pagination
                count={images?.length}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBack, next: ArrowForward }}
                    {...item}
                  />
                )}
                onChange={handlePagination}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.noDevice}>{t("image.No tms images found")}</div>
      )}
    </>
  );
}
