import { RouteObject, type LoaderFunction } from "react-router-dom";
import TreescoutDetailsPage, { TreescoutImage } from "./TreescoutDetailsPage";
import { getTmsImages, loadTmsImage } from "../../../../../API/treescout";

const routes: RouteObject = {
  Component: TreescoutDetailsPage,
  path: ":tmsId",
  loader: getTmsImages as LoaderFunction,
  children: [
    {
      path: ":sensorImageId",
      loader: loadTmsImage as LoaderFunction,
      Component: TreescoutImage,
    },
  ],
};

export default routes;
