import { LoaderFunctionArgs, Params } from "react-router-dom";
import { get, getImage } from "./AureaApi";

type GetCustomerDevicesParams = LoaderFunctionArgs & {
  params: Params<"customerId">;
};
type getTmsImagesParams = LoaderFunctionArgs & {
  params: Params<"tmsId">;
};
type loadTmsImageParams = LoaderFunctionArgs & {
  params: Params<"sensorImageId">;
};

export type TreescoutDevice = {
  id: string;
  reseller_id: number;
  customer_id: number;
  previous_customer_ids: number[];
  sending_messages: string;
  serial_number: string;
  created_at: string;
  updated_at: string;
};

type Camera = {
  serial_number: string;
  position_on_vehicle: {
    altitude_cm: number;
    gps_offset: {
      forward_back_cm: number;
      left_right_cm: number;
      altitude_cm: number;
    };
  };
  view_direction: {
    pitch_degrees: number;
    yaw_degrees: number;
    roll_degrees: number;
  };
  fov: {
    horizontal_degrees: number;
    vertical_degrees: number;
  };
};

type CameraPosition = "left" | "right";

export type SensorImage = {
  camera_serial_number: string;
  created_at: string;
  drive_id: string;
  id: string;
  position: {
    coordinates: number[];
    type: string;
  };
  raw_image_available: boolean;
  resolution: {
    horizontal_pixels: number;
    vertical_pixels: number;
  };
  timestamp: string;
  vehicle_parameters: {
    heading: number;
    position: {
      coordinates: number[];
      type: string;
    };
    speed_m_s: number;
  };
};

export type SensorImageWithCamera = SensorImage & {
  camera: Camera;
};

export type TreescoutDeviceList = TreescoutDevice[];

export async function getCustomerDevices({
  params: { customerId },
}: GetCustomerDevicesParams): Promise<null | TreescoutDeviceList> {
  return get<TreescoutDeviceList>(
    `/v1/customer/${customerId}/tms_installation`,
  );
}

export function getTmsImages({ params: { tmsId } }: getTmsImagesParams) {
  return get<SensorImage[]>(
    `/v1/tms_installation/${tmsId}/sensor_image?limit=5`,
  );
}

export async function loadTmsImage({
  params: { sensorImageId },
}: loadTmsImageParams): Promise<{
  url: Promise<string>;
  cameraPosition: CameraPosition;
}> {
  const url = getImage(`/v1/sensor_image/${sensorImageId}/webp`);
  const imageData = await get<SensorImageWithCamera>(
    `/v1/sensor_image/${sensorImageId}`,
  );

  const cameraPosition =
    imageData.camera.view_direction.yaw_degrees < 0 ? "left" : "right";

  return { url, cameraPosition };
}
